import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Player = makeShortcode("Player");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Monitor bus`}</h1>
    <p>{`Use Treemonster as shown in the video below. The trick is setting the "red dot" just below and to the left of the lowest fundamental pitches of the audio signal. (Some pre-filtering might help.) The other trick is setting the `}<strong parentName="p">{`Ring`}</strong>{` knob all the way OFF (counter-clockwise).`}</p>
    <Player playsInline src="https://cdn.discordapp.com/attachments/649293987848519691/710883264264405052/2020-05-15_10-07-AM.mp4" mdxType="Player" />
    <p>{`submitted by `}<a parentName="p" {...{
        "href": "https://discordapp.com/channels/121395249003233280/649293987848519691/710883264906133625"
      }}>{`baphy`}</a></p>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      